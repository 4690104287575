import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { artworks } from '../Data/artworks';
import { slugify } from '../utils/slugify'; // Import slugify to create URL-friendly slugs

const ArtworkSlider: React.FC = () => {
  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Display 3 artworks on desktop
    slidesToScroll: 1,
    arrows: true, // Show arrows for navigation
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />, // Custom Next Arrow
    prevArrow: <SamplePrevArrow />, // Custom Prev Arrow
    appendDots: (dots: any) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '10px',
        }}
      >
        <ul style={{ margin: '0' }}>{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024, // On tablets
        settings: {
          slidesToShow: 2, // Show 2 artworks
        },
      },
      {
        breakpoint: 768, // On mobile
        settings: {
          slidesToShow: 1.8, // Show 1.8 artworks for a nice overlap effect
        },
      },
    ],
  };

  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-sans mb-8">Discover Our Art</h2>

        {/* Slider for Artwork Cards */}
        <Slider {...settings}>
          {artworks.map((artwork, index) => (
            <div key={index} className="px-2">
              {/* Artwork Card */}
              <Link to={`/gallery/${slugify(artwork.title)}`} className="block bg-white">
                {/* Artwork Image */}
                <img
                  src={artwork.imageUrl}
                  alt={artwork.title}
                  className="w-full h-64 object-cover"
                  style={{ border: 'none' }}
                />

                {/* Artwork Details */}
                <div className="p-4">
                  <h3 className="text-lg font-sans">{artwork.title}</h3>
                  <p className="text-sm text-gray-500">{artwork.artist}</p>
                  <p className="text-xl mt-2">{artwork.price}</p>
                  <p className="text-sm text-gray-500">{artwork.date}</p>

                  {/* Availability and Edition Type */}
                  <div className="flex items-center mt-4 space-x-4">
                    <span
                      className={`text-sm ${
                        artwork.isAvailable ? 'text-green-600' : 'text-red-600'
                      }`}
                    >
                      {artwork.isAvailable ? 'Available' : 'Sold Out'}
                    </span>
                    <span className="text-sm text-gray-500">|</span>
                    <span className="text-sm text-gray-700">
                      {artwork.isLimitedEdition ? 'Limited Edition' : 'Standard Edition'}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

// Custom Next Arrow
const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'black',
        borderRadius: '50%',
        padding: '15px',
        right: '10px',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

// Custom Prev Arrow
const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'black',
        borderRadius: '50%',
        padding: '15px',
        left: '10px',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

export default ArtworkSlider;
