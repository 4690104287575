import React from 'react';

// Data for charities
const charities = [
  {
    name: "Ataxia UK",
    description: "Supporting those affected by ataxia. Holly Hipwell was featured in their publications for her contributions to disability awareness through art.",
    imageUrl: "/images/ataxia.jpeg",
    link: "https://www.ataxia.org.uk/",
    articleLink: "https://www.example.com/holly-ataxia-article", // Link to article for Ataxia UK
    articleTitle: "Read about Holly's feature in Ataxia UK's article"
  },
  {
    name: "Brain Charity",
    description: "Helping people with neurological conditions. Holly's work has been recognized for inspiring creativity in people with disabilities.",
    imageUrl: "/images/brain.png",
    link: "https://www.thebraincharity.org.uk/",
    articleLink: "https://www.example.com/holly-brain-charity-article", // Link to article for Brain Charity
    articleTitle: "Read about Holly's feature in The Brain Charity's article"
  },
  // Add more charities here as needed
];

const CharityLinks = () => {
  return (
    <section className="flex flex-col lg:flex-row h-auto lg:h-screen">
      {charities.map((charity, index) => (
        <React.Fragment key={index}>
          <div
            className="relative w-full lg:w-1/2 h-64 lg:h-full"
            style={{
              backgroundImage: `url(${charity.imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {/* Darkened overlay for better contrast */}
            <div className="absolute inset-0 bg-black opacity-80"></div> {/* Darker Background overlay */}
            <div className="relative z-10 flex flex-col justify-center items-center h-full p-8 text-white text-center">
              <h2 className="text-3xl font-bold mb-4">{charity.name}</h2>
              <p className="text-lg mb-6">{charity.description}</p>

              {/* Single Link focused on Holly's feature */}
              <a
                href={charity.articleLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-white text-black px-6 py-3"
              >
                {charity.articleTitle}
              </a>
            </div>
          </div>

          {/* Dividing Line between charity sections */}
          {index < charities.length - 1 && (
            <div className="block w-[2px] bg-black lg:block"></div> // Divider on all screen sizes
          )}
        </React.Fragment>
      ))}
    </section>
  );
};

export default CharityLinks;
