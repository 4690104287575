// src/MainPages/Exhibitions.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { exhibitions } from '../Data/exhibition';

const Exhibitions: React.FC = () => {
  return (
    <section className="bg-white py-12">
      <div className="container mx-auto px-4 lg:px-0">
        {/* Heading */}
        <h2 className="text-4xl font-bold mb-8">Exhibitions</h2>

        {/* Exhibition List */}
        <div className="space-y-12">
          {exhibitions.map((exhibition, index) => (
            <div key={index} className="lg:flex lg:space-x-16 mb-12">
              {/* Image Section */}
              <div className="lg:w-1/3">
                <Link to={`/exhibitions/${exhibition.id}`}>
                  <img
                    className="w-full h-[250px] lg:h-[300px] object-cover"
                    src={exhibition.images[0]}
                    alt={exhibition.name}
                  />
                </Link>
              </div>

              {/* Details Section */}
              <div className="lg:w-2/3 mt-6 lg:mt-0">
                <h3 className="text-2xl font-semibold">
                  <Link to={`/exhibitions/${exhibition.id}`}>{exhibition.name}</Link>
                </h3>
                <p className="text-lg text-gray-600 mb-2">
                  <span className="font-bold">Location: </span>{exhibition.location}
                </p>
                <p className="text-lg text-gray-600 mb-4">
                  <span className="font-bold">Date: </span>{exhibition.date}
                </p>
                <p className="text-gray-700">{exhibition.description}</p>
                <div className="mt-4">
                  <Link
                    to={`/exhibitions/${exhibition.id}`}
                    className="text-blue-500 hover:underline"
                  >
                    Read More &rarr;
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Exhibitions;
