import React from 'react';

export default function ArtistBio() {
  return (
    <section className="bg-white py-12">
      <div className="container mx-auto px-4 lg:px-0">
        {/* Heading with Underline */}
        <div className="mb-8">
          <h1 className="text-5xl  leading-tight">Holly Hipwell</h1>
          <hr className="border-t border-black opacity-30 mt-2 w-full" />
        </div>

        <div className="lg:flex lg:space-x-16">
          {/* Left Column (Personal Info in 3 columns) */}
          <div className="lg:w-3/5">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 mb-8">
              <div>
                <p className="font-semibold mb-2">Born:</p>
                <p className="mb-4">Norwich, England</p>
              </div>
              <div>
                <p className="font-semibold mb-2">Lives and works:</p>
                <p className="mb-4">Norwich, UK</p>
              </div>
              <div>
                <p className="font-semibold mb-2">Exhibitions:</p>
                <p className="mb-4">London Art Museum, UK Art Show</p>
              </div>
            </div>

            {/* Bio and Description */}
            <div>
              <p className="mb-6 text-gray-700">
                Holly Hipwell, an artist from Norwich, is inspired by impressionist painters and abstract works. Despite
                her challenges with Friedreich's ataxia, she continues to create intricate and imaginative drawings.
              </p>
              <p className="mb-6 text-gray-700">
                Holly blends different mediums such as watercolours and pastels, creating pieces that captivate the
                viewer's attention. Her work has been featured in exhibitions across the UK, and her unique approach
                continues to evolve with each new project.
              </p>
              <p className="text-gray-700">
                Known for her attention to detail, Holly's pieces stand out in their ability to merge intricate details
                with bold, expressive strokes of colour.
              </p>
            </div>
          </div>

          {/* Right Column (Embedded YouTube Video) */}
          <div className="lg:w-2/5 mt-8 lg:mt-0">
            <div className="w-full">
              <iframe
                className="w-full h-[350px] lg:h-[450px] object-cover"
                src="https://www.youtube.com/embed/8jY7-aMgSak"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
