import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
  const navigationLinks = [
    { name: 'HOME', path: '/' },
    { name: 'ABOUT', path: '/about' },
    { name: 'AVAILABLE WORK', path: '/gallery' },
    { name: 'EXHIBITIONS', path: '/exhibitions' },
  ];

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation(); // Get the current path

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className="sticky top-0 bg-white shadow-lg z-50 dark:bg-neutral-800 font-sans">
      <nav className="border-gray-200 px-4 lg:px-6 py-4 dark:bg-neutral-800">
        <div className="flex items-center justify-between mx-auto max-w-screen-xl">
          {/* Logo on the left */}
          <Link to="/" className="flex-shrink-0" onClick={handleLinkClick}>
            <img
              src="/images/HollyHipwell.png"
              className="h-8 sm:h-10"
              alt="Company Logo"
            />
          </Link>

          {/* Navigation menu for desktop on the right */}
          <div className="hidden lg:flex lg:items-center lg:space-x-6">
            <ul className="flex space-x-6 text-sm">
              {navigationLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.path}
                    className={`relative block py-1 pr-2 pl-3 transition-colors duration-300 ${
                      pathname === link.path
                        ? 'text-black dark:text-white'
                        : 'text-black dark:text-white'
                    }`}
                    aria-current={link.path === pathname ? 'page' : undefined}
                    onClick={handleLinkClick}
                  >
                    {link.name}
                    {/* Underline effect on hover */}
                    <span
                      className="absolute bottom-0 left-0 w-full h-[1px] bg-black dark:bg-white transition-all duration-300 transform scale-x-0 origin-bottom-left hover:scale-x-100"
                      style={{ paddingBottom: '1px' }}
                    ></span>
                  </Link>
                </li>
              ))}
            </ul>

            {/* Black square button for 'CONTACT' on desktop */}
            <Link
              to="/contact"
              className="bg-black text-white px-6 py-2 text-sm ml-4 hidden lg:inline-block"
            >
              CONTACT
            </Link>
          </div>

          {/* Hamburger menu for mobile view */}
          <div className="flex lg:hidden items-center">
            <button
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded={isMobileMenuOpen}
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              {!isMobileMenuOpen ? (
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
            </button>
          </div>
        </div>

        {/* Mobile menu dropdown */}
        {isMobileMenuOpen && (
          <div className="lg:hidden">
            <ul className="flex flex-col mt-4 space-y-2 text-sm">
              {navigationLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.path}
                    className={`relative block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 dark:text-white dark:hover:bg-gray-700 dark:border-gray-700 ${
                      pathname === link.path ? 'text-black dark:text-white' : ''
                    }`}
                    aria-current={link.path === pathname ? 'page' : undefined}
                    onClick={handleLinkClick}
                  >
                    {link.name}
                    {/* Underline effect on hover */}
                    <span
                      className="absolute bottom-0 left-0 w-full h-[1px] bg-black dark:bg-white transition-all duration-300 transform scale-x-0 origin-bottom-left hover:scale-x-100"
                      style={{ paddingBottom: '1px' }}
                    ></span>
                  </Link>
                </li>
              ))}

              {/* CONTACT link for mobile */}
              <li>
                <Link
                  to="/contact"
                  className="relative block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 dark:text-white dark:hover:bg-gray-700 dark:border-gray-700"
                  onClick={handleLinkClick}
                >
                  CONTACT
                  <span
                    className="absolute bottom-0 left-0 w-full h-[1px] bg-black dark:bg-white transition-all duration-300 transform scale-x-0 origin-bottom-left hover:scale-x-100"
                    style={{ paddingBottom: '1px' }}
                  ></span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
}
