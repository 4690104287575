import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

const AboveHeader: React.FC = () => {
  return (
    <div className="hidden lg:flex w-full bg-black py-2">
      <div className="container mx-auto flex justify-between items-center px-6">
        {/* Left side - Email */}
        <div className="flex items-center space-x-2">
          <FontAwesomeIcon icon={faEnvelope} className="text-white" />
          <a
            href="mailto:enquiries@hollyhipwellart.co.uk"
            className="text-white text-sm"
          >
            enquiries@hollyhipwellart.co.uk
          </a>
        </div>

        {/* Right side - Social icons */}
        <div className="flex items-center space-x-4">
          <a
            href="https://www.facebook.com/hollyhipwellart"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://www.instagram.com/hollyhipwellart"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboveHeader;
