// src/MainPages/Home.tsx
import React from 'react';
import ProjectSlider from '../PageComponents/HomeSelection';
import Hero from '../PageComponents/Hero'; // Your hero component
import ArtGallery from '../PageComponents/ArtGallery';
import CharityLinks from '../PageComponents/CharityLinks';

export default function Home() {
  return (
    <section>
      {/* Hero section */}
      <Hero />

      {/* Slider section using the artworks data */}
      <ProjectSlider />

      <ArtGallery />
      <CharityLinks />
    </section>
  );
}
