import { useParams } from 'react-router-dom';
import { artworks } from '../Data/artworks'; // Import artworks data
import { slugify } from '../utils/slugify'; // Import slugify function
import { Tab } from '@headlessui/react'; // Import headless UI for tab functionality

const ArtworkDetails: React.FC = () => {
  const { title } = useParams<{ title?: string }>(); // Get the title from URL params

  // Find the artwork by comparing the slugified title
  const artwork = artworks.find((artwork) => slugify(artwork.title) === title);

  if (!artwork) {
    return <div>Artwork not found</div>;
  }

  // Image gallery component
  const renderImageGallery = () => (
    <Tab.Group className="flex flex-col-reverse">
      {/* Image selector at the bottom */}
      <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
        <Tab.List className="grid grid-cols-4 gap-6">
          <Tab className="group relative flex h-24 cursor-pointer items-center justify-center  bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50">
            <span className="sr-only">{artwork.title}</span>
            <span className="absolute inset-0 overflow-hidden ">
              <img src={artwork.imageUrl} alt={artwork.title} className="h-full w-full object-cover object-center" />
            </span>
            <span
              aria-hidden="true"
              className="pointer-events-none absolute inset-0 ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
            />
          </Tab>
        </Tab.List>
      </div>

      {/* Main image display */}
      <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
        <Tab.Panel>
          <img src={artwork.imageUrl} alt={artwork.title} className="h-full w-full object-cover object-center" />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          
          {/* Image gallery section */}
          {renderImageGallery()}

          {/* Artwork info section */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">{artwork.title}</h1>
            
            <div className="mt-3">
              <p className="text-lg font-semibold">{`Price: ${artwork.price}`}</p>
              <p className="text-gray-500">{`Artist: ${artwork.artist}`}</p>
              <p className="text-gray-500">{`Date: ${artwork.date}`}</p>
            </div>

            <div className="mt-6">
              <p className="text-lg text-gray-700">{artwork.description}</p>
            </div>

            <button className="mt-10 flex w-full items-center justify-center  border border-transparent bg-black px-8 py-3 text-base font-medium text-white hover:bg-gray-800">
              Purchase Artwork
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtworkDetails;
