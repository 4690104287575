// src/Data/exhibition.ts
export const exhibitions = [
    {
        id: 1,
        name: "Swedish Project",
        theme: "neutral", // New theme property
        location: "Sweden",
        date: "Autumn 2024",
        description: "Holly's project in collaboration with Laura Blake for the Plush project.",
        detailedDescription: `Holly participated in the Swedish Project, working on a unique collaboration with Laura Blake as part of the Plush project. The project focused on a mixture of contemporary and abstract art, reflecting Holly's deep connection to form and emotion. 
          
          Holly’s work for this project involved exploring new techniques and themes, merging her signature abstract style with the overarching vision of the Plush project. The artwork showcased during the exhibition was met with critical acclaim for its vibrant use of color and emotive depth.`,
        images: [
          "/images/Sweden/380343776_1018875155991652_2443478999074630547_n.png",
          "/images/Sweden/380551416_347120517985000_4163084830717616433_n.png",
          "/images/Sweden/380238080_653629960302443_1390673070255840098_n.png",
          "/images/Sweden/380267090_1395548327665381_6509606496943252777_n.png",
          "/images/Sweden/380320483_885125883137937_647472281461045535_n-1.png",
          "/images/Sweden/380346625_867232694700832_4582583713743034114_n.png",
          "/images/Sweden/380402513_847569853506254_890892595588093678_n.png",
          "/images/Sweden/380636309_2940420506092474_3710179225921500425_n.png",
          "/images/Sweden/384543443_324082123597573_2608839797086325125_n.png",
          "/images/Sweden/384568233_1550511795763824_737346022756108053_n.png",
          "/images/Sweden/385516063_633770475496813_2960945973251617548_n.png",
          "/images/Sweden/385522129_2056531144714606_5147999068722539014_n.png",
          "/images/Sweden/385529430_1798976747201110_5362116924565761627_n.png",
          "/images/Sweden/385529453_1073392370340128_5843024361409936584_n.png",
          "/images/Sweden/385529475_1220362578897931_4727870075840208509_n.png",
          "/images/Sweden/385544012_645517557696539_5453592269712922253_n.png",
          "/images/Sweden/385559135_194971226982527_2669553064343097175_n.png",
          "/images/Sweden/386833094_1913667725715381_3985037225009284850_n.png",
          "/images/Sweden/386858839_340226502018408_5374266795366121962_n.png"
        ],

      },
//     {
//       id: 2,
//       name: "Norwich Artists Collective",
//       location: "Norwich Gallery",
//       date: "June 2022",
//       description: "An exhibition featuring a collective of local artists, exploring various mediums and abstract works.",
//       detailedDescription: `Holly participated in the Norwich Artists Collective, collaborating with fellow local artists to explore various mediums and abstract concepts.
  
//   She showcased a series of works focusing on the interplay between color and emotion, leaving a lasting impression on the visitors.`,
//       images: [
//         "https://via.placeholder.com/600x400?text=Norwich+Collective+Image+1",
//         "https://via.placeholder.com/600x400?text=Norwich+Collective+Image+2",
//         "https://via.placeholder.com/600x400?text=Norwich+Collective+Image+3",
//       ],
//     },
//     {
//       id: 3,
//       name: "Impressions of the Sea",
//       location: "Brighton Pavilion",
//       date: "September 2021",
//       description: "A unique exhibition focusing on marine life and ocean landscapes, combining modern techniques with classic approaches.",
//       detailedDescription: `In "Impressions of the Sea," Holly's contributions included a series of paintings inspired by the sea. She used her signature mix of watercolours and pastels to capture the essence of marine life.
  
//   Her work was celebrated for its ability to merge intricate details with bold, expressive strokes of color.`,
//       images: [
//         "https://via.placeholder.com/600x400?text=Impressions+Sea+Image+1",
//         "https://via.placeholder.com/600x400?text=Impressions+Sea+Image+2",
//         "https://via.placeholder.com/600x400?text=Impressions+Sea+Image+3",
//       ],
//     },
  ];
  