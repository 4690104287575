import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { exhibitions } from '../Data/exhibition';

const ExhibitionDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const exhibitionId = Number(id);
  const exhibition = exhibitions.find((ex) => ex.id === exhibitionId);

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const { top } = sectionRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (top < windowHeight * 0.75) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!exhibition) {
    return <div>Exhibition not found</div>;
  }

  // Function to split the description into paragraphs
  const renderDescription = (description: string) => {
    return description.split('\n\n').map((paragraph, index) => (
      <p key={index} className="text-lg text-gray-700 mb-6">
        {paragraph.trim()}
      </p>
    ));
  };

  return (
    <div
      ref={sectionRef}
      className={`bg-gray-100 pb-16 transition-all duration-1000 transform ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <div className="container mx-auto px-6 lg:px-8 max-w-screen-xl">
        {/* Back to All Exhibitions link */}
        <Link to="/exhibitions" className="text-gray-800 hover:text-gray-600 mt-4 mb-4 inline-block">
          &larr; Back to All Exhibitions
        </Link>

        {/* Main Layout: Images on Left (2/3) and Text on Right (1/3) */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Images Section (2/3 width on desktop) */}
          <div className="lg:col-span-2">
            {/* Main Image */}
            <img
              src={exhibition.images[0]}
              alt={exhibition.name}
              className="w-full h-auto object-cover mb-6"
            />

            {/* Smaller Images in grid layout */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {exhibition.images.slice(1).map((image, index) => (
                <img
                  key={`image-${index}`}
                  src={image}
                  alt={`Exhibition ${index + 2}`}
                  className="w-full h-auto object-cover"
                />
              ))}
            </div>
          </div>

          {/* Text Section (1/3 width on desktop) */}
          <div className="lg:col-span-1">
            <h1 className="text-4xl font-light mb-4 text-gray-900 leading-tight">
              {exhibition.name}
            </h1>

            {/* Line Divider */}
            <hr className="border-gray-300 mb-6" />

            <div>{renderDescription(exhibition.detailedDescription)}</div>
          </div>
        </div>

        {/* Similar Exhibitions Section */}
        <div className="w-full mt-16">
          <h2 className="text-3xl font-light mb-8">Other Exhibitions</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {exhibitions
              .filter((ex) => ex.id !== exhibitionId)
              .slice(0, 3)
              .map((ex) => (
                <article
                  key={ex.id}
                  className="relative isolate flex flex-col justify-end overflow-hidden bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 transition-transform duration-300 transform hover:scale-105"
                >
                  <img
                    src={ex.images[0]}
                    alt={ex.name}
                    className="absolute inset-0 -z-10 h-full w-full object-cover"
                  />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black via-black/40" />
                  <h3 className="mt-3 text-lg font-light text-white">
                    <Link to={`/exhibitions/${ex.id}`}>
                      <span className="absolute inset-0" />
                      {ex.name}
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-300">
                    {ex.description.substring(0, 100)}...
                  </p>
                </article>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionDetails;
