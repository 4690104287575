import React, { useEffect, useRef } from 'react';
import Button from '../CustomComponents/buttons';

export default function Hero() {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Start video at 31 seconds
      video.currentTime = 31;

      const handleTimeUpdate = () => {
        // Loop back to 31 seconds when reaching 50 seconds
        if (video.currentTime >= 50) {
          video.currentTime = 31;
        }
      };

      video.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, []);

  return (
    <section className="relative h-screen w-full overflow-hidden">
      {/* Video Background */}
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="/images/Holly(2020).mp4"
        autoPlay
        loop={false}
        muted
        playsInline
      />

      {/* Overlay for the content */}
      <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-center z-10">
        <div className="max-w-2xl px-4">
          <h1 className="text-4xl font-elegant tracking-wide leading-snug md:text-5xl xl:text-6xl text-white">
            Art That Speaks Emotion and Strength
          </h1>
          <p className="mt-4 text-lg font-sans text-white leading-relaxed">
            Holly's abstract art reflects her journey of resilience, drawing from personal experiences with ataxia. Each piece is crafted to inspire and connect with the human spirit, blending form, color, and emotion into every stroke.
          </p>
          <div className="mt-6 flex justify-center space-x-4">
            <Button variant="primary" to="/gallery">Explore The Collection</Button>
            <Button variant="secondary" to="/about">Meet The Artist</Button>
          </div>
        </div>

        {/* Scroll Down Indicator */}
        <div className="absolute bottom-[9rem] flex justify-center w-full animate-bounce">
          <p className="text-white text-sm font-semibold">Scroll Down</p>
          <svg
            className="w-6 h-6 text-white ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}
