import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { artworks } from '../Data/artworks';
import { exhibitions } from '../Data/exhibition';
import { Link } from 'react-router-dom';
import { slugify } from '../utils/slugify';

interface ArtworkProps {
  title: string;
  artist: string;
  description: string;
  price: string;
  imageUrl: string;
  date: string;
  isAvailable: boolean;
  isLimitedEdition: boolean;
  theme: 'light' | 'dark' | 'neutral';
}

interface ExhibitionProps {
  id: number;
  name: string;
  location: string;
  date: string;
  description: string;
  detailedDescription: string;
  images: string[];
  theme: 'light' | 'dark' | 'neutral';
}

const combinedData: (ArtworkProps | ExhibitionProps)[] = [
  ...artworks.map((artwork) => ({
    ...artwork,
    theme: artwork.theme as 'light' | 'dark' | 'neutral',
  })),
  ...exhibitions.map((exhibition) => ({
    ...exhibition,
    theme: exhibition.theme as 'light' | 'dark' | 'neutral',
  })),
];

const ProjectSlider: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slideInterval = 5000; // 5 seconds per slide

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setCurrentSlide((prev) => (prev + 1) % combinedData.length);
    },
    onSwipedRight: () => {
      setCurrentSlide((prev) => (prev - 1 + combinedData.length) % combinedData.length);
    },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % combinedData.length);
    }, slideInterval);

    return () => clearInterval(interval);
  }, []);

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  const currentSlideData = combinedData[currentSlide];

  const getThemeClasses = (theme: 'light' | 'dark' | 'neutral') => {
    switch (theme) {
      case 'light':
        return {
          bg: 'bg-white',
          text: 'text-gray-900',
        };
      case 'dark':
        return {
          bg: 'bg-gray-900',
          text: 'text-white',
        };
      case 'neutral':
        return {
          bg: 'bg-[#e4dfd6]',
          text: 'text-gray-700',
        };
      default:
        return {
          bg: 'bg-[#e4dfd6]',
          text: 'text-gray-700',
        };
    }
  };

  const themeClasses = getThemeClasses(currentSlideData.theme);

  return (
    <div className={`relative ${themeClasses.bg}`} {...handlers} draggable="false" style={{ userSelect: 'none' }}>
      {/* Main Container */}
      <div className="flex flex-col lg:flex-row lg:h-[80vh] lg:px-0 transition duration-1000 ease-in-out transform h-[100vh]">
        
        {/* Image Section for Mobile - Image on top for mobile */}
        <div className="w-full h-[55%] lg:w-[50%] lg:h-full flex lg:relative lg:items-center order-first lg:order-last transition-all">
          <div className="w-full lg:h-full h-full">
            <img
              src={'imageUrl' in currentSlideData ? currentSlideData.imageUrl : currentSlideData.images[0]}
              alt={'title' in currentSlideData ? currentSlideData.title : currentSlideData.name}
              className="w-full h-full object-cover object-top"  // object-top to keep top in view
              draggable="false"
              onContextMenu={(e) => e.preventDefault()}
              style={{ userSelect: 'none' }}
            />
          </div>
        </div>

        {/* Text Section */}
        <div className="container mx-auto px-4 sm:px-6 lg:px-0 lg:flex lg:items-center lg:w-[50%] lg:pl-20 transition-all h-[45%] lg:h-full">
          <div className="pb-4 pt-8 sm:pb-12 sm:pt-12 lg:pt-32 lg:h-full">
            <h3 className={`font-sans text-4xl mb-2 ${themeClasses.text}`}>
              {'title' in currentSlideData ? currentSlideData.title : currentSlideData.name}
            </h3>
            <p className={`font-light text-2xl mb-2 ${themeClasses.text}`}>
              {currentSlideData.date}
            </p>
            <p className={`text-lg mb-2 ${themeClasses.text}`}>
              {currentSlideData.description}
            </p>

            {'price' in currentSlideData && (
              <p className={`text-lg font-bold mb-2 ${themeClasses.text}`}>
                {`Price: ${(currentSlideData as ArtworkProps).price}`}
              </p>
            )}

            {'price' in currentSlideData ? (
              <Link
                to={`/gallery/${slugify((currentSlideData as ArtworkProps).title)}`}
                className={`bg-black text-white px-6 py-3 inline-block`}
              >
                View Artwork
              </Link>
            ) : (
              <Link
                to={`/exhibitions/${(currentSlideData as ExhibitionProps).id}`}
                className={`bg-black text-white px-6 py-3 inline-block`}
              >
                View Exhibition
              </Link>
            )}

            {/* Navigation dots */}
            <div className="mt-4 flex space-x-2">
              {combinedData.map((_, index) => (
                <div
                  key={index}
                  onClick={() => goToSlide(index)}
                  className={`cursor-pointer h-[6px] w-[6px] rounded-full transition-all duration-300 ${
                    currentSlide === index ? 'bg-black' : 'bg-gray-400'
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSlider;
