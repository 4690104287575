export const artworks = [
  {
    title: "Autumn 2024 | Framed Set of Six",
    artist: "Holly Hipwell",
    price: "£6,750.00",
    imageUrl: "/images/canvas1.png",
    date: "Autumn 2024",
    isAvailable: true,
    isLimitedEdition: true,
    description: "This artwork is part of a limited edition framed set of six, showcasing autumnal themes.",
    theme: "light", // Valid theme
  },
  {
    title: "Motorcycle Riders",
    artist: "Holly Hipwell",
    price: "£10,950.00",
    imageUrl: "/images/canvas2.png",
    date: "Spring 2023",
    isAvailable: true,
    isLimitedEdition: true,
    description: "A striking depiction of motorcycle riders by Bob Dylan.",
    theme: "neutral", // Valid theme
  },
  {
    title: "Bean There, Drunk That",
    artist: "Holly Hipwell",
    price: "£295.00",
    imageUrl: "/images/canvas3.png",
    date: "Winter 2023",
    isAvailable: true,
    isLimitedEdition: true,
    description: "A whimsical artwork by Peter Smith.",
    theme: "light", // Valid theme
  },
  {
    title: "Go Your Own Way",
    artist: "Holly Hipwell",
    price: "£1,650.00",
    imageUrl: "/images/canvas4.png",
    date: "Summer 2023",
    isAvailable: true,
    isLimitedEdition: true,
    description: "A captivating piece by Nic Joly.",
    theme: "neutral", // Valid theme
  },
];
