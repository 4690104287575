import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './MainPages/Home';
import About from './MainPages/About';
import Gallery from './MainPages/gallery';
import Contact from './MainPages/contact';
import Layout from './Layout/layout';
import { ThemeProvider } from './CustomComponents/darkmode';
import ScrollToTop from './CustomComponents/ScrollToTop';
import PrivacyPolicy from './MainPages/privacypolicy';
import TermsConditions from './MainPages/TermsConditions';
import Exhibitions from './MainPages/Exhibitions';
import ArtworkDetails from './MainPages/GalleryDetails';
import ExhibitionDetails from './MainPages/exhibitionDetails';

import './App.css';

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <ScrollToTop />
      
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/gallery/:title' element={<ArtworkDetails />} /> {/* Dynamic route for artwork */}
            <Route path='/contact' element={<Contact />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-conditions' element={<TermsConditions />} />
            <Route path='/exhibitions' element={<Exhibitions />} />
            <Route path='/exhibitions/:id' element={<ExhibitionDetails />} /> {/* Dynamic route for exhibition details */}
          </Route>
        </Routes>
   
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
