import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../utils/slugify';
import { artworks } from '../Data/artworks';

const AvailableWork: React.FC = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="container mx-auto px-6 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Available Work</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Explore Holly's current available artworks, including limited editions.
        </p>

        <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
          {artworks.map((artwork, index) => (
            <article key={index} className="relative isolate flex flex-col gap-8 lg:flex-row">
              <div className="relative aspect-[4/3] sm:aspect-[4/3] lg:aspect-[4/3] lg:w-[40%] lg:shrink-0"> {/* Adjust aspect ratio and width */}
                <img
                  alt={artwork.title}
                  src={artwork.imageUrl}
                  className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
                />
              </div>

              <div className="flex-1">
                <div className="flex items-center gap-x-4 text-xs">
                  <time dateTime={artwork.date} className="text-gray-500">
                    {artwork.date}
                  </time>
                  <span className="relative z-10 bg-gray-50 px-3 py-1.5 font-medium text-gray-600">
                    {artwork.artist}
                  </span>
                </div>

                <div className="group relative max-w-xl">
                  <h3 className="mt-3 text-2xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600"> {/* Increase font size */}
                    <Link to={`/gallery/${slugify(artwork.title)}`}>
                      {artwork.title}
                    </Link>
                  </h3>

                  <p className="mt-2 text-lg font-bold text-gray-900">Price: {artwork.price}</p>
                  <p className={`mt-2 text-sm ${artwork.isAvailable ? 'text-green-600' : 'text-red-600'}`}>
                    {artwork.isAvailable ? 'Available' : 'Sold Out'}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    {artwork.isLimitedEdition ? 'Limited Edition' : 'Regular Edition'}
                  </p>
                </div>

                <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                  <div className="relative flex items-center gap-x-4">
                    <div className="text-sm leading-6">
                      <Link
                        to={`/gallery/${slugify(artwork.title)}`}
                        className="inline-block bg-black text-white px-6 py-3 hover:bg-gray-800 transition" // Larger button
                      >
                        View Artwork
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvailableWork;
